const LOCALE_MAP = {
  '/nl/nl': 'nl-NL',
  '/nl/en': 'en-GB',
  '/nl/fr': 'fr-FR',
};

export function formatNumber(num: number) {
  const langCookie = useCookie<keyof typeof LOCALE_MAP>('gootrans');

  return new Intl.NumberFormat(LOCALE_MAP[langCookie.value] || 'nl-NL', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
}
